@import "./import.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.center {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  color: $font;
}
.flex {
  display: flex;
}
.flex1 {
  flex: 1;
}

.headline {
  font-weight: 600;
  letter-spacing: 0.128px;
  font-size: 32px;
  line-height: 36px;
  @include lg {
    font-size: 56px;
    line-height: 1.07143;
  }
}
.subline {
  letter-spacing: 0.228px;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.10722;

  @include lg {
    font-size: 28px;
    margin-top: 10px;
   
  }
}

.orange {
  color: $orange;
}

.button {
  all: unset;
  background: $orange;
  border: none;
  outline: none;
  font-size: 15px;
  height: 34px;
  border-radius: 17px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  cursor: pointer;
  &:hover {
    //lighten color a bit
    background: lighten($orange, 10%);
  }
}
.title {
  font-size: 150%;
  @include md {
    font-size: 200%;
  }
  &.right {
    text-align: right;
  }
}
.bold {
  font-weight: bold;
}
.line {
  height: 1px;
  background-color: $black;
  margin-top: 24px;
  margin-bottom: 24px;
}

.section {
  .inner {
    padding: 24px;
    margin: 0 auto;
    max-width: 1200px;
  }
  @include md {
    padding: 48px;
  }
  &.dark {
    background-color: #222;
    color: $white;
    .line {
      background-color: #f2f2f2;
    }
  }
  &.grey {
    background-color: #f2f2f2;
  }
}
