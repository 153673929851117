@import "../../import.scss";

.Advantages {
  background: $white;
}

.mobAdvantages {
  @include md {
    display: none;
  }
  .advantage {
    margin-bottom: 36px;
    .title {
      font-size: 120%;
      margin-bottom: 12px;
    }
    .text {
      color: #666;
    }
  }
}

.desktopAdvantages {
  display: none;
  @include md {
    display: flex;
  }
  .list {
    display: flex;
    flex-direction: column;
    flex: 2;

    font-size: 120%;
    .advantage {
      height: 48px;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
    }
  }
  .text {
    flex: 5;
    font-weight: 300;
    font-size: 120%;
    color: #444;
  }
}
