@import "../../import.scss";

.Nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;

  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(245, 245, 247);
  backdrop-filter: saturate(180%) blur(20px);
  flex-wrap: wrap;

  .desktopNav {
    display: none;
    flex-direction: column;
    align-items: flex-end;
  }
  .mobileNav {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    text-align: center;
  }
  .mobileNavButton {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .buttonIcon {
      position: absolute;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  @include lg {
    flex-direction: row;
    align-items: center;
    height: 44px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 12px;
    padding-right: 12px;
    .mobileNav {
      display: none;
    }
    .mobileNavButton {
      display: none;
    }
    .desktopNav {
      flex-direction: row;
      display: flex;
    }
  }
  .logo {
    font-size: 150%;
    color: inherit;
    text-decoration: none;
  }
  .navItem {
    opacity: 0.88;
    transition: opacity 0.5s, color 0.5s;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: white;
      opacity: 1;
    }
  }
}
