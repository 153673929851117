@import "../../import.scss";

.Aufklapper {
  min-height: calc(100vh - 44px);
  padding-top: 44px;
  background-color: black;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: none;
  @include lg {
    display: flex;
  }
  &.mobile {
    display: flex;
    @include lg {
      display: none;
    }
  }
  &:first-child {
    .anchor {
      top: 0;
    }
  }

  &.color-white {
    background-color: white;
    color: $font;
  }
  &.color-grey {
    background-color: #888;
  }

  &.rightText {
    .textContainer {
      @include lg {
        align-items: flex-end;
        text-align: right;
        white-space: wrap;
        max-width: 30%;
        padding: 0;
      }
    }
    .inner {
      .spacerOne {
        @include lg {
          flex: 4;
        }
      }
      @include lg {
        .spacerTwo {
          flex: 1;
        }
      }
    }
  }
  .inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    @include lg {
      flex-direction: row;
    }
    .spacerOne {
      flex: 2;
      @include lg {
        flex: 1;
      }
    }
    .spacerTwo {
      flex: 4;
    }
  }
  .textContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    @include lg {
      text-align: left;
      max-width: 30%;
    }
  }
  .subline {
    height: auto;
  }
  .greyline {
    color: #86868b;
    line-height: 25px;
    letter-spacing: -0.765px;
    font-size: 17px;

    @include lg {
      margin-top: 24px;
      font-size: 21px;
      line-height: 1.381;
    }
  }
  .moreLink {
    color: $blue;
    text-decoration: none;
    font-size: 15px;
    margin-top: 4px;
    @include lg {
      margin-top: 8px;
      font-size: 17px;
    }
    &:hover {
      text-decoration: underline;
      color: #0077ed;
    }
  }
}

.moreImages {
  display: flex;
  flex-direction: column;
  font-size: 0;
  min-width: 0;
  &.open {
    img {
      max-height: 100vh;
    }
  }
  img {
    width: 100%;
    max-height: 0px;
    object-fit: cover;
    transition: max-height 2s;
    min-width: 0;
  }
}
