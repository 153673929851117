@import "../../import.scss";

.SubScreen {
  padding-bottom: 80px;
  padding-left: 12px;
  padding-right: 12px;
  .backButton {
    position: fixed;
    top: 60px;
    right: 8px;
    background-color: $orange;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    z-index: 20;
  }
  .imageContainer {
    margin-top: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 68px auto;
    margin-bottom: 0;
    width: 100%;
    position: relative;
    .imageButton {
      position: absolute;
      top: 50%;
      left: 10px;
      color: white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00000077;
      cursor: pointer;
      &.next {
        right: 10px;
        left: auto;
      }
    }
    img {
      width: 100%;
      object-fit: cover;
      max-height: 600px;
    }
  }
  .title {
    margin-top: 24px;
    white-space: pre-wrap;
    max-width: 1200px;
    margin: 24px auto;
    width: 100%;
  }
  .line {
    margin-left: 12px;
    margin-right: 12px;
    height: 1px;
    min-height: 1px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 48px;
  }
  .trippleArea {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 12px;
    max-width: 1200px;
    margin: 0 auto;
    white-space: pre-wrap;
    width: 100%;
    @include md {
      display: flex;
      gap: 24px;
    }
    .left {
      line-height: 250%;
      white-space: pre-wrap;
      margin-bottom: 24px;
      font-size: 90%;
      font-weight: bold;
    }
    .left,
    .right,
    .mid {
      flex: 1;
    }
  }

  .credit {
    font-size: 90%;
    margin-top: 40px;
    color: #444;
    text-align: right;
    flex: 1;
  }
}
