@import "../../import.scss";

.Layout {
  display: flex;
  flex-direction: column;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  scroll-snap-type: y;
  min-height: 0px;
}
