$font: #1d1d1f;
$blue: #06c;
$blue2: #0038a8;
$orange: #f2c011;
$pink: #d60270;
$pink2: #e6598d;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xlg {
  @media (min-width: 850px) {
    @content;
  }
}

$white: #f2f2f2;
$black: #222;
