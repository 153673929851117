@import "../../import.scss";

.ContactForm {
  display: flex;
  gap: 20px;
  padding-bottom: 0;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 48px;
  width: 100%;
  scroll-snap-align: start;
  @include lg {
    flex-direction: row;
  }
  .contactTitle {
    font-weight: bold;
    font-size: 200%;
    margin-left: 12px;
  }
  .contactContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 12px;
    margin-left: 12px;
    .flex {
      flex-direction: column;
      @include lg {
        flex-direction: row;
      }
    }
  }
  .contactInput {
    flex: 1;
    font-family: "Quicksand", sans-serif;
    outline: none;
    border: none;
    border: 1px solid #bbb;
    padding: 12px;
    border-radius: 4px;
  }
  .contactButton {
    background-color: white;
    margin-top: 10px;
    background-color: $orange;
    color: white;
    font-size: 120%;
    border: none;
    font-weight: bold;
    border-radius: 8px;
    height: 45px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: lighten($orange, 10%);
    }
  }
}
.emailText {
  align-items: center;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  .emailLink {
    margin-top: 15px;
    color: inherit;
    font-size: 180%;
    font-weight: 100;
    text-decoration: none;
  }
}
