@import "../../import.scss";
.anchor {
  position: relative;

  height: 0;
  max-height: 0px;
  display: block;
  width: 0;
  overflow: hidden;
  top: -44px;
  @include lg {
  }
}
a {
  text-decoration: none;
}
.Block {
  background-color: black;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: none;
  scroll-snap-align: start;
  text-decoration: none;
  * {
    text-decoration: none;
  }
  .subline {
    white-space: pre-wrap;
    font-size: 140%;
    margin-top: 24px;
    line-height: 1.3em;
  }
  @include lg {
    display: flex;
  }
  &.mobile {
    display: flex;

    min-height: calc(100vh - 44px);
    padding-top: 44px;
    @include lg {
      display: none;
    }
  }
  min-height: calc(100vh - 44px);
  padding-top: 44px;
  &:first-child {
    .anchor {
      top: 1px;
    }
  }

  &.color-white {
    background-color: white;
    color: $font;
  }
  &.color-grey {
    background-color: #888;
  }
  &.rightText {
    .inner {
      .spacerOne {
        @include lg {
          flex: 4;
        }
      }
      @include lg {
        .spacerTwo {
          flex: 1;
        }
      }
    }
  }
  .inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    @include lg {
      flex-direction: row;
    }
    .spacerOne {
      flex: 2;
      @include lg {
        flex: 1;
      }
    }
    .spacerTwo {
      flex: 4;
    }
  }
  .textContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .greyline {
    color: #86868b;
    line-height: 25px;
    letter-spacing: -0.765px;
    font-size: 17px;
    @include lg {
      margin-top: 24px;
      font-size: 21px;
      line-height: 1.381;
    }
  }
  .moreLink {
    color: $orange;
    text-decoration: none;
    font-size: 15px;
    margin-top: 4px;
    color: white;
    background-color: $orange;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 8px;
    @include lg {
      margin-top: 8px;
      font-size: 17px;
    }
    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }
  }
}
