@import "../../import.scss";

.VRPrices {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include md {
    flex-direction: row;
    justify-content: center;
    align-items: unset;
    gap: 24px;
  }
  .priceBox {
    border-radius: 6px;
    background-color: white;
    margin: 48px;
    display: flex;
    flex-direction: column;
    @include md {
      margin: 0;
    }
    max-width: 250px;
    width: 100%;
    padding: 24px;
    .title {
      font-weight: bold;
    }
    .bullet {
      display: flex;
    }
    .price {
      font-weight: bold;
    }
  }
}
