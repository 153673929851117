@import "../../import.scss";

.Workflow {
  @include md {
    display: flex;

    justify-content: center;
  }
  .arrow {
    display: none;
    @include md {
      width: 80px;
      font-size: 200%;

      display: flex;
      justify-content: center;
      margin-top: 120px;
    }
  }
  .workflowPart {
    @include md {
      flex: 1;
      width: 100%;
      max-width: 250px;
      .title {
        height: 150px;
      }
    }
    margin-top: 40px;
    margin-bottom: 40px;
    .num {
      margin-bottom: 0px;
      font-size: 120%;
      @include md {
        margin-bottom: 48px;

      }
    }
    .text {
      margin-top: 24px;
      font-size: 80%;
    }
  }
}
