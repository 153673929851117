@import "../../import.scss";

.ProjectBox {
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
  padding-top: 24px;
  border-top: 1px solid $black;
  color: inherit;
  text-decoration: none;
  .arrow {
    text-align: right;
    @include md {
      text-align: left;
    }
  }
  &:last-child {
    padding-bottom: 24px;
  }
  @include md {
    display: flex;
    gap: 12px;
    margin-right: 0;
    margin-left: 0;
  }
  .image {
    display: flex;
    overflow: hidden;
    align-items: center;

    @include md {
      width: 500px;
      align-items: flex-start;
      justify-content: flex-start;
    }
    img {
      height: 200px;
      object-fit: cover;
      flex: 1;
      @include md {
        height: 250px;
      }
    }
  }
  .count {
    font-size: 200%;
    font-weight: 300;
    @include md {
      width: 80px;
      text-align: center;
    }
  }
  .infos {
    display: flex;
    flex-direction: column;
    .title {
      margin-bottom: 12px;
    }
    @include md {
      flex: 2;
    }
  }
}
