html {
  -webkit-font-smoothing: antialiased;
  height: 100%;
  display: flex;
  flex-direction: column;
}
body {
  -webkit-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.4705882353;
  font-weight: 400;
  letter-spacing: -0.022em;
  overflow-x: visible;
  scroll-behavior: smooth;
  display: flex;
  flex: 1;
  flex-direction: column;

  position: relative;
  min-height: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hiddenAnchor {
  display: block;
  height: 0;
  width: 0;
  position: relative;
  top: -44px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  flex: 1;
}
